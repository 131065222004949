import { getFullName } from '@/helpers/fullName'

const MONTHLY_ID = 1
const ALL_YEAR_ID = 2

const NEW_STUDENT = 1

export default {
  selectedProducts(state) {
    return state.products.filter((product) => product.isSelected)
  },

  price(state, getters) {
    const sum = getters.selectedProducts.reduce((acc, product) => {
      const price =
        state.invoicePeriodType.id === ALL_YEAR_ID
          ? product.annualPrice
          : product.offer.price

      return acc + price
    }, 0)

    return sum || null
  },

  productParams(state) {
    return {
      grade: {
        id: state.studentConsumer.grade?.id,
      },
      student: {
        id: state.studentConsumer.id,
      },
    }
  },

  checkoutParams(state, getters) {
    const offerIds = getters.selectedProducts.map((product) => ({
      id: product.offer.id,
    }))

    const data = {
      invoicePeriodType: {
        id: state.invoicePeriodType?.id,
      },
      grade: {
        id: state.studentConsumer.grade.id,
      },
      offers: offerIds,
    }

    return {
      client: {
        studentConsumer: state.studentConsumer,
        ...data,
      },
      student: data,
    }
  },

  hasCurrentStudentOnlineSchoolOrder(state) {
    return (studentId) => {
      const foundStudent = state.options.students.find(
        (student) => student.id === studentId,
      )

      return foundStudent?.hasOnlineSchoolOrder ?? false
    }
  },

  paymentTypes(state) {
    return state.options.paymentTypes.map((type) => {
      if (type.id === MONTHLY_ID) {
        return {
          ...type,
          description: 'Банковская карта и другие способы оплаты',
        }
      }

      if (type.id === ALL_YEAR_ID) {
        return {
          ...type,
          description: 'В том числе материнским капиталом',
        }
      }

      return type
    })
  },

  studentsOptions(state) {
    return state.options.students?.map((student) => ({
      ...student,
      name: getFullName(student),
    }))
  },

  hasStudents(state) {
    return state.options.students.length > 0
  },

  isSelectNewStudent(state) {
    return state.currentStudentType?.id === NEW_STUDENT
  },

  isOrderSameAsInitial(state) {
    const result = {
      period:
        state.initialOrder.currentOrder?.invoicePeriodType?.id ===
        state.invoicePeriodType?.id,
      products: state.initialOrder.items.every(
        (product, index) =>
          product.isSelected === state.products[index].isSelected,
      ),
    }

    return Object.values(result).every((value) => value)
  },
}
