import { RouterUser } from '@/RouterUser'
import authHelper from '@/helpers/auth'
import { mainSchool } from '@/helpers/const/AppData'
import { isLocalNetwork } from '@/helpers/const/isLocalNetwork'
import { trackActionTypes } from '@/helpers/const/trackActionTypes'
import { redirectAfterLogin } from '@/helpers/redirectHelper'
import { showToast, showToastPersistent } from '@/helpers/toast'
import { i18n } from '@/i18n/setup'
import { eventBus } from '@/pubsub/bus'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiLogin from 'ApiRest/Api/Auth/Login'
import ApiSchool from 'ApiRest/Api/Auth/Options/School'
import { ApiTwoFactorAuth } from 'ApiRest/Api/Auth/TwoFactorAuth'
import ApiSubdomainList from 'ApiRest/Api/School/UnionSubDomain'
import { ApiUserAction } from 'ApiRest/Api/UserAction'
import Constants from 'Constants'
import { isUndefined } from 'lodash'

/**
 * @param {Object} args
 * @param {number} args.id
 * @param {string} args.email
 * @param {string} args.role
 */
const globalMessageOnLogin = function ({ id, email, role }) {
  eventBus.$emit('frontend.auth.userLoggedIn', {
    id,
    email,
    role,
  })
}

const globalMessageOnLogoutSilent = function () {
  eventBus.$emit('frontend.auth.userLoggedOutAfterPasswordChange')
}

const globalMessageOnLogout = function () {
  eventBus.$emit('frontend.auth.userLoggedOut')
}

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchSchoolPlatform({ state, commit }) {
    if (!state.schoolPlatform) {
      let { id } = mainSchool

      if (!isLocalNetwork()) {
        const domain = window.domains.main
        const { data: subdomainsList = [] } = await ApiSubdomainList.get(domain)

        commit('setInputValue', {
          name: 'schoolData',
          value: subdomainsList[0],
        })

        if (subdomainsList.length) {
          const school = subdomainsList.find((subdomain) => subdomain.self)

          if (school) {
            id = school.schoolId
          }
        }
      }

      try {
        const { data } = await ApiSchool.get(id)

        commit('setInputValue', {
          name: 'schoolPlatform',
          value: data,
        })
      } catch {
        commit('setInputValue', {
          name: 'schoolPlatform',
          value: {},
        })
      }
    }
  },

  async login({ state, commit, rootGetters }) {
    commit('setIsLoading', true)

    const payload = {
      email: state.email,
      password: state.password,
    }

    if (state.firebasePushToken) {
      payload.firebase_push_token = state.firebasePushToken
    }

    try {
      const { data } = await ApiLogin.post(payload)
      const { baseRole, id, authCode } = data

      commit('setInputValue', {
        value: id,
        name: 'id',
      })

      commit('setInputValue', {
        value: state.email,
        name: 'emailLogged',
      })

      commit('setInputValue', {
        value: baseRole,
        name: 'userRole',
      })

      commit('setInputValue', {
        value: authCode,
        name: 'authCode',
      })

      commit(
        'common/user/setUserValue',
        {
          key: 'role',
          value: data.baseRole,
        },
        {
          root: true,
        },
      )

      const isClient = rootGetters['common/user/isClient']
      const isStudent = rootGetters['common/user/isStudent']

      if (isClient || isStudent) {
        globalMessageOnLogin({
          id: state.id,
          email: state.emailLogged,
          role: state.baseRole,
        })
      }

      if (isStudent) {
        await ApiUserAction.post({
          actionTypeId: trackActionTypes.LOGIN,
          entityId: null,
        })
      }

      if (isUndefined(authCode)) {
        redirectAfterLogin()
      }
    } catch (error) {
      const status = error?.response?.status
      const code = error?.response?.data?.code
      const message = error?.response?.data?.message

      if (
        status === HttpStatusCodes.Conflict &&
        code === Constants.authorizationResponse.CODE_ERR_USER_EMPTY_PASSWORD
      ) {
        showToast(
          i18n.global.t(
            'store.auth.toasted_message.success.for_security_reasons',
          ),
          'error',
        )

        commit('setInputValue', {
          name: 'showInfoComponent',
          value: true,
        })
      } else if (message) {
        if (code === HttpStatusCodes.FailedDependency) {
          commit('setInputValue', {
            name: 'isDataEnteredCorrectly',
            value: true,
          })

          showToastPersistent(message, 'error')
        } else {
          showToast(message, 'error')
        }
      }
    } finally {
      commit('setIsLoading', false)
    }
  },

  async validateSmsCode(context, payload) {
    await ApiTwoFactorAuth.post(payload)
  },

  async resendSmsCode(context, payload) {
    await ApiTwoFactorAuth.put(payload)
  },

  async logoutSilent() {
    globalMessageOnLogoutSilent()

    await ApiLogin.delete()

    authHelper.actions.clearLocalStorage()
  },

  async logout({ dispatch, commit, rootGetters }) {
    commit('setIsGlobalLoading', true, {
      root: true,
    })

    const goToLogin = async () => {
      if (RouterUser.router.currentRoute.name !== 'login') {
        await RouterUser.router.push({
          name: 'login',
        })
      }
    }

    const isStudent = rootGetters['common/user/isStudent']

    try {
      if (isStudent) {
        await ApiUserAction.post({
          actionTypeId: trackActionTypes.LOGOUT,
          entityId: null,
        })
      }

      await ApiLogin.delete()

      authHelper.actions.clearLocalStorage()
      globalMessageOnLogout()

      dispatch('resetGlobalState', null, {
        root: true,
      })

      await goToLogin()
    } catch (error) {
      const status = error?.response?.status
      const message = error?.response?.data?.message

      if (status === HttpStatusCodes.Forbidden) {
        await goToLogin()
      } else if (message) {
        showToast(message, 'error')
      }
    } finally {
      commit('setIsGlobalLoading', false, {
        root: true,
      })
    }
  },
}
