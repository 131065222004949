<template>
  <router-view v-if="!isLoading" />
  <LoaderFullScreen v-else />

  <ModalsContainer />
</template>

<script>
import LoaderFullScreen from '@/components/common/LoaderFullScreen.vue'
import { isShowcaseDomain } from '@/helpers/domain'
import { initTrackers } from '@/helpers/trackers/init'
import { defineComponent } from 'vue'
import { ModalsContainer } from 'vue-final-modal'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'App',

  components: {
    LoaderFullScreen,
    ModalsContainer,
  },

  data() {
    return {
      isLoadingFonts: true,
    }
  },

  head() {
    const trackerScripts = []

    if (process.iblsEnv.IBLS_TRACKER_DOMAIN) {
      trackerScripts.push({
        src: `${process.iblsEnv.IBLS_TRACKER_DOMAIN}/t.js`,
        async: true,
      })
    }

    if (
      process.iblsEnv.IBLS_TRACKER_DOMAIN &&
      process.iblsEnv.IBLS_TRACKER_SCRIPT
    ) {
      trackerScripts.push({
        src: `${process.iblsEnv.IBLS_TRACKER_DOMAIN}/${process.iblsEnv.IBLS_TRACKER_SCRIPT}`,
        async: true,
      })
    }

    const resultHead = {
      script: [],

      titleTemplate: (titleChunk) => {
        if (isShowcaseDomain) {
          return titleChunk || 'Академия IBLS'
        }

        return titleChunk ? `${titleChunk} | IBLS` : `IBLS`
      },
    }

    if (trackerScripts.length > 0) {
      resultHead.script.push(...trackerScripts)
    }

    return resultHead
  },

  computed: {
    ...mapState([
      'isGlobalLoading',
    ]),

    isLoading() {
      return this.isGlobalLoading || this.isLoadingFonts
    },
  },

  watch: {
    isGlobalLoading: {
      async handler(newValue) {
        if (!newValue) {
          await initTrackers()
        }
      },

      immediate: true,
    },
  },

  created() {
    this.init()
  },

  methods: {
    async init() {
      await this.initFonts()
    },

    async initFonts() {
      try {
        await document.fonts.ready
      } finally {
        this.isLoadingFonts = false
      }
    },
  },
})
</script>

<style lang="scss">
@import '~@/app';
</style>
