<template>
  <VueFinalModal
    :model-value="modelValue"
    class="modal"
    :content-class="[$style.modalContent, contentClass]"
    :overlay-class="overlayClass"
    content-transition="vfm-fade"
    overlay-transition="vfm-fade"
    :click-to-close="clickToClose"
    :esc-to-close="escToClose"
    :content-style="cssVars"
    :modal-id="name"
    @update:model-value="onUpdateModelValue"
    @before-close="onBeforeClose"
    @before-open="onBeforeOpen"
    @opened="onOpened"
    @closed="onClosed"
    @click-outside="onClickOutside"
  >
    <template #default="{ close }">
      <div :class="['vf__content-inner', $style.modalInner]">
        <ElButton
          v-if="showCloseBtn"
          class="btn-img overflow-visible"
          :class="$style.modalClose"
          @click="() => onClickBtnClose(close)"
        >
          <img :src="iconClose" alt="close" />
        </ElButton>

        <slot v-if="isRendered.title" name="title">
          <div :class="['h3', $style.title]">{{ title }}</div>
        </slot>

        <slot />
      </div>
    </template>
  </VueFinalModal>
</template>

<script setup>
import iconClose from '@/static/images/common-lk/icons/close.svg'
import ElButton from '@/ui/ElButton.vue'
import { isNumber } from 'lodash'
import { computed, useCssModule, useSlots } from 'vue'
import { VueFinalModal } from 'vue-final-modal'

const $style = useCssModule()

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: undefined,
  },
  name: {
    type: [
      String,
      Number,
      Symbol,
    ],
    default: undefined,
  },
  width: {
    type: [
      String,
      Number,
    ],
    default: 600,
  },
  clickToClose: {
    type: Boolean,
    default: false,
  },
  escToClose: {
    type: Boolean,
    default: false,
  },
  showCloseBtn: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: undefined,
  },
  contentClass: {
    type: String,
    default: undefined,
  },
  overlayClass: {
    type: String,
    default: undefined,
  },
})

const emit = defineEmits([
  'update:modelValue',
  'click:close',
  'before-close',
  'before-open',
  'opened',
  'closed',
  'click-outside',
])

const slots = useSlots()

const isRendered = computed(() => ({
  title: slots.title || props.title,
}))

/**
 *
 * @param {boolean} val
 */
function onUpdateModelValue(val) {
  emit('update:modelValue', val)
}

/**
 *
 * @param {Function} event
 */
function onBeforeClose(event) {
  emit('before-close', event)
}

/**
 *
 * @param {Function} event
 */
function onBeforeOpen(event) {
  emit('before-open', event)
}

/**
 *
 */
function onOpened() {
  emit('opened')
}

/**
 *
 */
function onClosed() {
  emit('closed')
}

/**
 *
 */
function onClickOutside() {
  emit('click-outside')
}

const cssVars = computed(() => {
  const width = isNumber(props.width) ? `${props.width}px` : `${props.width}`

  const result = {
    '--vf-max-width': width,
  }

  return result
})

/**
 *
 * @param {Function} close
 */
function onClickBtnClose(close) {
  close()
  emit('update:modelValue', false)
  emit('click:close')
}
</script>

<script>
export default {}
</script>

<style lang="scss">
@import 'vue-final-modal/style.css';
@import '~@/scss/variables';

.vfm {
  display: flex;
  align-items: center;
  justify-content: center;

  &__overlay {
    background: rgba($blue-1, 0.9);
  }
}

.h3-modal {
  margin: 0 0 16px;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  letter-spacing: 0;

  @media (min-width: $sm) {
    font-size: 18px;
    line-height: 24px;
  }
}
</style>

<style lang="scss" module>
@import '~@/scss/variables';

:root {
  --vf-max-width: 600px;
}

.title {
  margin: 0 0 16px;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  letter-spacing: 0;

  @media (min-width: $sm) {
    font-size: 18px;
    line-height: 24px;
  }
}

.modalClose {
  overflow: visible;
  align-self: flex-end;

  @media (min-width: $sm) {
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 2;
    margin: 0;
  }
}

.modalContent {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 16px;
  overflow-y: auto;
  background: $white;

  @media (min-width: $sm) {
    max-width: var(--vf-max-width);
    height: auto;
    max-height: 90vh;
    padding: 24px;
    border-radius: 8px;
  }
}

.modalInner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
