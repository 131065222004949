import { clientPaymentRoutes } from '@/routes/showcase/client/clientPaymentRoutes'
import { clientSubjectRoutes } from '@/routes/showcase/client/clientSubjectRoutes'

export const clientEducationRoutes = [
  {
    path: 'education',
    name: 'client-showcase-education',

    component: () => import('@/layouts/showcase/ClientEducationLayout.vue'),

    children: [
      ...clientSubjectRoutes,
      ...clientPaymentRoutes,

      {
        path: 'purchases',
        name: 'client-showcase-purchases',
        component: () => import('@/pages/showcase/common/Purchases.vue'),
      },
    ],
  },
]
