import averageMark from '@/store/client/averageMark/store'
import calendar from '@/store/client/calendar/store'
import contracts from '@/store/client/contracts/store'
import demoAccess from '@/store/client/demoAccess/store'
import demoFeSimple from '@/store/client/demoFeSimple/store'
import events from '@/store/client/events/store'
import extramuralCertification from '@/store/client/extramuralCertification/store'
import familyCertification from '@/store/client/familyCertification/store'
import familyCertificationRequest from '@/store/client/familyCertificationRequest/store'
import fullTimeCertification from '@/store/client/fullTimeCertification/store'
import fullTimeStatement from '@/store/client/fullTimeStatement/store'
import goals from '@/store/client/goals/store'
import homework from '@/store/client/homework/store'
import incomeDiagnostics from '@/store/client/incomeDiagnostics/store'
import invoices from '@/store/client/invoices/store'
import journal from '@/store/client/journal/store'
import lessons from '@/store/client/lessons/store'
import library from '@/store/client/library/store'
import main from '@/store/client/main/store'
import news from '@/store/client/news/store'
import notifications from '@/store/client/notifications/store'
import profile from '@/store/client/profile/store'
import promoCampaign from '@/store/client/promoCampaign/store'
import scheduleLessons from '@/store/client/scheduleLessons/store'
import specialStatement from '@/store/client/specialStatement/store'
import statements from '@/store/client/statements/store'
import statementsOnlineSchool from '@/store/client/statementsOnlineSchool/store'
import statementsShowcase from '@/store/client/statementsShowcase/store'
import students from '@/store/client/students/store'
import subscription from '@/store/client/subscription/store'
import tariff from '@/store/client/tariff/store'
import theContract from '@/store/client/theContract/store'
import theContractSimplified from '@/store/client/theContractSimplified/store'
import productAd from '@/store/common/productAd/store'

export default {
  namespaced: true,

  modules: {
    specialStatement,
    journal,
    calendar,
    contracts,
    demoAccess,
    extramuralCertification,
    familyCertification,
    fullTimeCertification,
    familyCertificationRequest,
    goals,
    incomeDiagnostics,
    invoices,
    lessons,
    main,
    news,
    notifications,
    profile,
    promoCampaign,
    statements,
    statementsShowcase,
    statementsOnlineSchool,
    students,
    subscription,
    theContract,
    homework,
    fullTimeStatement,
    averageMark,
    scheduleLessons,
    events,
    theContractSimplified,
    library,
    demoFeSimple,
    productAd,
    tariff,
  },
}
