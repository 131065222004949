import { RouterUser } from '@/RouterUser'
import { testTypes } from '@/helpers/const/testTypes'
import { trackActionTypes } from '@/helpers/const/trackActionTypes'
import dateFormatHelper from '@/helpers/dateFormatHelper'
import snapshotTesting from '@/helpers/snapshotTesting'
import { showToast } from '@/helpers/toast'
import { i18n } from '@/i18n/setup'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import { ApiTimestamp } from 'Api/system/Timestamp'
import { ApiStudentQuestionReset } from 'ApiRest/Api/Account/Student/Question/Reset'
import { ApiStudentQuestionTest } from 'ApiRest/Api/Account/Student/Question/Test'
import ApiAccountStudentTestAnswerList from 'ApiRest/Api/Account/Student/Test/Answer/List'
import ApiAccountStudentTestAnswerListActive from 'ApiRest/Api/Account/Student/Test/Answer/ListActive'
import ApiAccountStudentTestAnswerOnCheck from 'ApiRest/Api/Account/Student/Test/Answer/OnCheck'
import ApiAccountStudentTestAnswerRetry from 'ApiRest/Api/Account/Student/Test/Answer/Retry'
import ApiAskQuestion from 'ApiRest/Api/Account/Student/Test/AskQuestion'
import ApiAccountStudentTestAttempt from 'ApiRest/Api/Account/Student/Test/Attempt'
import ApiAccountStudentTestAttemptList from 'ApiRest/Api/Account/Student/Test/Attempt/List'
import ApiAccountStudentTestAttemptStarted from 'ApiRest/Api/Account/Student/Test/Attempt/Started'
import ApiAccountStudentCertTest from 'ApiRest/Api/Account/Student/Test/CertTest'
import ApiAccountStudentCertTestTestingCreate from 'ApiRest/Api/Account/Student/Test/CertTest/TestingCreate'
import ApiAccountStudentCertTestTestingList from 'ApiRest/Api/Account/Student/Test/CertTest/TestingList'
import ApiAccountStudentCtpTest from 'ApiRest/Api/Account/Student/Test/CtpTest'
import ApiAccountStudentCtpTestTestingCreate from 'ApiRest/Api/Account/Student/Test/CtpTest/TestingCreate'
import ApiAccountStudentCtpTestTestingList from 'ApiRest/Api/Account/Student/Test/CtpTest/TestingList'
import ApiAccountStudentFeCertTest from 'ApiRest/Api/Account/Student/Test/FeCertTest'
import ApiAccountStudentFeCertTestTestingCreate from 'ApiRest/Api/Account/Student/Test/FeCertTest/TestingCreate'
import ApiAccountStudentFeCertTestTestingList from 'ApiRest/Api/Account/Student/Test/FeCertTest/TestingList'
import ApiAccountStudentFteCtpTest from 'ApiRest/Api/Account/Student/Test/FteCtpTest'
import ApiAccountStudentFteCtpTestTestingCreate from 'ApiRest/Api/Account/Student/Test/FteCtpTest/TestingCreate'
import ApiAccountStudentFteCtpTestTestingList from 'ApiRest/Api/Account/Student/Test/FteCtpTest/TestingList'
import ApiAccountStudentFteFinalTest from 'ApiRest/Api/Account/Student/Test/FteFinalTest'
import ApiAccountStudentFteFinalTestTestingCreate from 'ApiRest/Api/Account/Student/Test/FteFinalTest/TestingCreate'
import ApiAccountStudentFteFinalTestTestingList from 'ApiRest/Api/Account/Student/Test/FteFinalTest/TestingList'
import ApiAccountStudentFtePreliminaryTest from 'ApiRest/Api/Account/Student/Test/FtePreliminaryTest'
import ApiAccountStudentFtePreliminaryTestTestingCreate from 'ApiRest/Api/Account/Student/Test/FtePreliminaryTest/TestingCreate'
import ApiAccountStudentFtePreliminaryTestTestingList from 'ApiRest/Api/Account/Student/Test/FtePreliminaryTest/TestingList'
import ApiAccountStudentTestTestingOnCheck from 'ApiRest/Api/Account/Student/Test/Testing/OnCheck'
import ApiAccountStudentTestTestingReturnByStudent from 'ApiRest/Api/Account/Student/Test/Testing/ReturnByStudent'
import { ApiQuestionPreview } from 'ApiRest/Api/Library/Section/Topic/Question/Preview'
import ApiTestingCheckTest from 'ApiRest/Api/Testing/Check/Test'
import ApiTestingTestPreview from 'ApiRest/Api/Testing/TestPreview'
import { ApiUserAction } from 'ApiRest/Api/UserAction'
import Constants from 'Constants'
import { isWithinInterval, parseISO } from 'date-fns'
import { millisecondsInMinute, millisecondsInSecond } from 'date-fns/constants'
import { maxBy } from 'lodash'

export default {
  async setLocale({ commit, dispatch }) {
    await dispatch('common/locale/fetchLangOptions', null, {
      root: true,
    })

    dispatch('common/locale/setLangDefault', null, {
      root: true,
    })

    commit('common/locale/setUserType', 'student', {
      root: true,
    })

    commit('common/locale/setInstitutionType', 'school', {
      root: true,
    })

    await dispatch('common/locale/changeLocale', null, {
      root: true,
    })
  },

  /**
   * Получение вопроса по ID
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @param {number} questionId - ID вопроса
   * @returns {Promise<void>}
   */
  async fetchQuestionPreview({ commit, dispatch }, questionId) {
    try {
      const { data } = await dispatch('fetchQuestionTestPreview', questionId)

      commit('setQuestionPreview', data)
    } catch (error) {
      // TODO: -> component
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        showToast(
          i18n.global.t('store.student.toasted_message.question_was_not_found'),
          'error',
        )

        await RouterUser.router.push({
          name: 'not-found',
        })
      }

      throw error
    }
  },

  /**
   * Получение вопроса для прохождения учеником
   * @param {Object} context
   * @param {{ context: Object, questionId: number }} payload
   * @returns {Promise<Object>}
   */
  fetchQuestionTest(context, payload) {
    return ApiStudentQuestionTest.get(payload)
  },

  /**
   * Получение превью вопроса
   * @param {Object} context
   * @param {number} questionId
   * @returns {Promise<Object>}
   */
  fetchQuestionTestPreview(context, questionId) {
    return ApiQuestionPreview.get(questionId)
  },

  /**
   * Получение теста по ID
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} testId - ID теста
   * @returns {Promise<void>}
   */
  async fetchTestPreview({ commit }, testId) {
    try {
      const { data } = await ApiTestingTestPreview.get(testId)

      commit('setTestPreview', data)
    } catch (error) {
      // TODO: -> component
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        showToast(
          i18n.global.t('store.student.toasted_message.test_was_not_found'),
          'error',
        )

        await RouterUser.router.push({
          name: 'not-found',
        })
      }

      throw error
    }
  },

  async fetchTestResultsPreview({ commit }, testId) {
    const { data } = await ApiTestingCheckTest.get(testId)

    commit('setTestResultsPreview', data)
  },

  /**
   * Получение КТП-Теста по ID
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} ctpTestId - ID КТП-Теста
   * @returns {Promise<void>}
   */
  async fetchCtpTest({ commit }, ctpTestId) {
    const { data } = await ApiAccountStudentCtpTest.get(ctpTestId)

    commit('setTest', data)
    commit('setTestPointsMarks')
  },

  /**
   * Получение теста аттестации Заочки по ID
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} certTestId - ID теста аттестации
   * @returns {Promise<void>}
   */
  async fetchCertTest({ commit }, certTestId) {
    const { data } = await ApiAccountStudentCertTest.get(certTestId)

    /**
     * whenShowAnswerType - в аттестационных тестах всегда "После прохождения теста"
     * isAvailable - вычисляется из периодов
     */
    const resultData = {
      ...data,
      whenShowAnswerType: {
        id: Constants.whenShowAnswerType.AFTER_TESTING,
        name: i18n.global.t('store.student.toasted_message.after_passing_test'),
      },
      isAvailable: data.periods.some((period) => {
        const start = parseISO(dateFormatHelper.toIso(period.startDateTime))
        const end = parseISO(dateFormatHelper.toIso(period.endDateTime))

        return isWithinInterval(new Date(), {
          start,
          end,
        })
      }),
    }

    commit('setTest', resultData)
    commit('setTestPointsMarks')
  },

  /**
   * Получение теста аттестации СО по ID
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} feCertTestId - ID теста аттестации СО
   * @returns {Promise<void>}
   */
  async fetchFeCertTest({ commit }, feCertTestId) {
    const { data } = await ApiAccountStudentFeCertTest.get(feCertTestId)

    commit('setTest', data)
    commit('setTestPointsMarks')
  },

  /**
   * Получение Очного КТП-Теста по ID
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} fteCtpTestId - ID КТП-Теста
   * @returns {Promise<void>}
   */
  fetchFteCtpTest: async ({ commit }, fteCtpTestId) => {
    const { data } = await ApiAccountStudentFteCtpTest.get(fteCtpTestId)

    commit('setTest', data)
    commit('setTestPointsMarks')
  },

  /**
   * Получение Очного Предварительного Теста по ID
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} ftePreliminaryTestId - ID Предварительного Теста
   * @returns {Promise<void>}
   */
  fetchFtePreliminaryTest: async ({ commit }, ftePreliminaryTestId) => {
    const { data } =
      await ApiAccountStudentFtePreliminaryTest.get(ftePreliminaryTestId)

    commit('setTest', data)
    commit('setTestPointsMarks')
  },

  /**
   * Получение Очного Итогового Теста по ID
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} fteFinalTestId - ID Итогового Теста
   * @returns {Promise<void>}
   */
  fetchFteFinalTest: async ({ commit }, fteFinalTestId) => {
    const { data } = await ApiAccountStudentFteFinalTest.get(fteFinalTestId)

    commit('setTest', data)
    commit('setTestPointsMarks')
  },

  /**
   * Получение списка Прохождения Теста у КТП-Теста
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} ctpTestId - ID КТП-Теста
   * @returns {Promise<void>}
   */
  fetchCtpTestingList: async ({ commit }, ctpTestId) => {
    const { data } = await ApiAccountStudentCtpTestTestingList.get(ctpTestId)

    commit('setTestingList', data)
  },

  /**
   * Получение списка Прохождения Теста у теста аттестации Заочки
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} certTestId - ID теста аттестации
   * @returns {Promise<void>}
   */
  fetchCertTestingList: async ({ commit }, certTestId) => {
    const { data } = await ApiAccountStudentCertTestTestingList.get(certTestId)

    commit('setTestingList', data)
  },

  /**
   * Получение списка Прохождения Теста у теста аттестации СО
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} feCertTestId - ID теста аттестации СО
   * @returns {Promise<void>}
   */
  fetchFeCertTestingList: async ({ commit }, feCertTestId) => {
    const { data } =
      await ApiAccountStudentFeCertTestTestingList.get(feCertTestId)

    commit('setTestingList', data)
  },

  /**
   * Получение списка Прохождения Теста у теста аттестации СО
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} fteCtpTestId - ID теста аттестации СО
   * @returns {Promise<void>}
   */
  fetchFteCtpTestingList: async ({ commit }, fteCtpTestId) => {
    const { data } =
      await ApiAccountStudentFteCtpTestTestingList.get(fteCtpTestId)

    commit('setTestingList', data)
  },

  /**
   * Получение списка Прохождения очного предварительного Теста
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} ftePreliminaryTestId - ID предварительного теста
   * @returns {Promise<void>}
   */
  fetchFtePreliminaryTestingList: async ({ commit }, ftePreliminaryTestId) => {
    const { data } =
      await ApiAccountStudentFtePreliminaryTestTestingList.get(
        ftePreliminaryTestId,
      )

    commit('setTestingList', data)
  },

  /**
   * Получение списка Прохождения очного итогового Теста
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} fteFinalTestId - ID итогового теста
   * @returns {Promise<void>}
   */
  fetchFteFinalTestingList: async ({ commit }, fteFinalTestId) => {
    const { data } =
      await ApiAccountStudentFteFinalTestTestingList.get(fteFinalTestId)

    commit('setTestingList', data)
  },

  /**
   * Перевести Прохождение Теста в статус "На проверке" (завершить прохождение)
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @returns {Promise<void>}
   */
  async sendTestForChecking({ state, commit, dispatch }) {
    const { data } = await ApiAccountStudentTestTestingOnCheck.put(
      state.testing.id,
    )

    commit('setTesting', data)

    await dispatch('fetchAnswerList', state.attempt.id)
    await dispatch('fetchAttempt', state.attempt.id)

    snapshotTesting.clearSnapshot(state.attempt.id)
  },

  /**
   * Запустить Прохождение
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @returns {Promise<void>}
   */
  startTesting: async ({ state, commit, dispatch }) => {
    await dispatch('fetchAttemptList', state.testing.id)

    const testingCurrentAttempt = state.attemptList.find(
      (attempt) => attempt.isCurrent,
    )

    commit('setAttempt', testingCurrentAttempt)

    await dispatch('startAttempt', state.attempt.id)
    await dispatch('fetchAnswerListActive')

    const testingTime = state.test.testingTime * millisecondsInMinute

    commit('setEstimatedTime', testingTime)
  },

  /**
   * Запустить Прохождение КТП-Теста (начать прохождение)
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @returns {Promise<void>}
   */
  async startCtpTesting({ state, commit, dispatch }) {
    const { data } = await ApiAccountStudentCtpTestTestingCreate.post(
      state.test.id,
    )

    commit('setTesting', data)

    await dispatch('startTesting')
  },

  /**
   * Запустить Прохождение Теста аттестации Заочки (начать прохождение)
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @returns {Promise<void>}
   */
  async startCertTesting({ state, commit, dispatch }) {
    const { data } = await ApiAccountStudentCertTestTestingCreate.post(
      state.test.id,
    )

    commit('setTesting', data)

    await dispatch('startTesting')
  },

  /**
   * Создать прохождение теста Аттестации СО
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} testId
   * @returns {Promise<void>}
   */
  async createFeCertTesting({ commit }, testId) {
    try {
      const { data } =
        await ApiAccountStudentFeCertTestTestingCreate.post(testId)

      commit('setTesting', data)
    } catch (error) {
      if (
        error?.response?.status !== HttpStatusCodes.Ok ||
        error?.response?.status !== HttpStatusCodes.Created
      ) {
        // TODO: -> component
        const errorMessage =
          error?.response?.data?.message ??
          i18n.global.t('store.student.toasted_message.error_has_occurred')

        showToast(errorMessage, 'error')
      }

      throw error
    }
  },

  /**
   * Запустить прохождение Очного КТП-теста (начать прохождение)
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @returns {Promise<void>}
   */
  startFteCtpTestTesting: async ({ state, commit, dispatch }) => {
    const { data } = await ApiAccountStudentFteCtpTestTestingCreate.post(
      state.test.id,
    )

    commit('setTesting', data)

    await dispatch('startTesting')
  },

  /**
   * Запустить прохождение Очного предварительного теста (начать прохождение)
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @returns {Promise<void>}
   */
  startFtePreliminaryTestTesting: async ({ state, commit, dispatch }) => {
    const { data } =
      await ApiAccountStudentFtePreliminaryTestTestingCreate.post(state.test.id)

    commit('setTesting', data)

    await dispatch('startTesting')
  },

  /**
   * Запустить прохождение Очного КТП-теста (начать прохождение)
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @returns {Promise<void>}
   */
  startFteFinalTestTesting: async ({ state, commit, dispatch }) => {
    const { data } = await ApiAccountStudentFteFinalTestTestingCreate.post(
      state.test.id,
    )

    commit('setTesting', data)

    await dispatch('startTesting')
  },

  /**
   * Запустить Прохождение Теста аттестации СО (начать прохождение)
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @returns {Promise<void>}
   */
  async startFeCertTesting({ state, commit, dispatch }) {
    await dispatch('createFeCertTesting', state.test.id)
    await dispatch('fetchAttemptList', state.testing.id)

    const testingCurrentAttempt = state.attemptList.find(
      (attempt) => attempt.isCurrent,
    )

    commit('setAttempt', testingCurrentAttempt)

    await dispatch('startAttempt', state.attempt.id)

    snapshotTesting.clearSnapshot(state.testing.id)

    await dispatch('fetchAnswerListActive')

    snapshotTesting.questionsSet({
      testingId: state.testing.id,
      questions: state.answerList,
    })

    const testingTime = state.test.testingTime * millisecondsInMinute

    commit('setEstimatedTime', testingTime)
  },

  /**
   * Получить список Попыток для указанного Прохождения Теста
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} testingId - ID Прохождение Теста
   * @returns {Promise<void>}
   */
  async fetchAttemptList({ commit }, testingId) {
    const { data } = await ApiAccountStudentTestAttemptList.get(testingId)

    commit('setAttemptList', data)
  },

  /**
   * Получить Попытку по ID
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} attemptId - ID Попытки
   * @returns {Promise<void>}
   */
  async fetchAttempt({ commit }, attemptId) {
    const { data } = await ApiAccountStudentTestAttempt.get(attemptId)

    commit('setAttempt', data)
  },

  /**
   * Начать Попытку Прохождения Теста
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @returns {Promise<void>}
   */
  async startAttempt({ state, commit }) {
    const { data, status } = await ApiAccountStudentTestAttemptStarted.put(
      state.attempt.id,
    )

    if (status === HttpStatusCodes.Ok) {
      commit('setAttempt', data)
    }
  },

  /**
   * Получить список вопросов
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @returns {Promise<void>}
   */
  async fetchAnswerList({ state, commit }) {
    const { data } = await ApiAccountStudentTestAnswerList.get(state.attempt.id)

    commit('setAnswerList', data)
  },

  /**
   * Получить список вопросов
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @returns {Promise<boolean>}
   */
  async fetchAnswerListActive({ state, commit }) {
    const { data: questions } = await ApiAccountStudentTestAnswerListActive.get(
      state.attempt.id,
    )

    // TODO: начало костыля для получения отвеченных вопросов
    const compareResult = await snapshotTesting.questionsCompareWithSnapshot({
      attemptId: state.attempt.id,
      questions,
    })

    // если есть недополученные вопросы или снэпшота на локале нет
    if (compareResult !== true && !!compareResult?.length) {
      const compareResultIds = compareResult.map(({ id }) => id)

      const { data: questionsMissed } =
        await ApiAccountStudentTestAnswerList.get(state.attempt.id)

      const missedQuestions = questionsMissed.filter(({ id }) =>
        compareResultIds.includes(id),
      )

      const result = [
        ...missedQuestions,
        ...questions,
      ].sort((a, b) => a.id - b.id)

      commit('setAnswerList', result)

      return true
    }

    // TODO: конец костыля

    commit('setAnswerList', questions)

    return true
  },

  /**
   * Получение вопроса для прохождения учеником
   * @param {Object} context
   * @param {{ context: Object, questionId: number }} payload
   * @returns {Promise<Object>}
   */
  takeTheQuestionTestAgain(context, payload) {
    return ApiStudentQuestionReset.put(payload)
  },

  /**
   * Перевести Ответ в статус "На проверке"
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @param {Object} payload.answer
   * @param {number} payload.answerId
   * @returns {Promise<void>}
   */
  async sendAnswerForChecking({ commit }, { answerId, answer }) {
    const { data } = await ApiAccountStudentTestAnswerOnCheck.put(
      answerId,
      answer,
    )

    commit('setAnswer', data)
    commit('updateAnswerList')
  },

  /**
   * Сбросить Ответ
   * @param {Object} context
   * @param {number} answerId
   * @returns {Promise<void>}
   */
  async retryAnswer(context, answerId) {
    await ApiAccountStudentTestAnswerRetry.put(answerId)
  },

  /**
   * Определяет доступность теста к прохождению
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @param {number} attemptId
   * @returns {Promise<boolean>}
   */
  async prepareForTesting({ state, commit, dispatch }, attemptId) {
    // Прохождение на проверке
    const testingOnCheck = state.testingList.find(
      (testing) => Constants.testingStatus.ON_CHECK === testing.status.id,
    )

    // Прохождение завершено
    const testingCompleted = state.testingList.find(
      (testing) => Constants.testingStatus.COMPLETED === testing.status.id,
    )

    const testingReturnedByStudent = state.testingList.find(
      (testing) =>
        testing.status.id === Constants.testingStatus.RETURNED_BY_STUDENT,
    )

    if (testingOnCheck) {
      commit('setTesting', testingOnCheck)
    }

    if (testingCompleted) {
      commit('setTesting', testingCompleted)
    }

    if (testingReturnedByStudent) {
      commit('setTesting', testingReturnedByStudent)
    }

    if (testingCompleted || testingOnCheck || testingReturnedByStudent) {
      await dispatch('fetchAttemptList', state.testing.id)
      // Текущая попытка прохождения
      let testingCurrentAttempt

      if (attemptId) {
        testingCurrentAttempt = state.attemptList.find(
          (attempt) => attempt.id === attemptId,
        )
      } else {
        testingCurrentAttempt = state.attemptList.find(
          (attempt) => attempt.isCurrent,
        )
      }

      commit('setAttempt', testingCurrentAttempt)

      await dispatch('fetchAnswerList', state.attempt.id)

      return false
    }

    //  ктп-тест доступен по времени
    const isAvailableByDateTime = state.test.isAvailable

    // Допустимые для продолжения прохождения статусы
    const canContinueStatusesList = [
      Constants.testingStatus.IN_PROCESS,
    ]

    // Есть ли в списке прохождений, доступное для продолжения
    const containsCanContinueStatus = state.testingList.some((testing) =>
      canContinueStatusesList.includes(testing.status.id),
    )

    if (isAvailableByDateTime && containsCanContinueStatus) {
      // Текущие прохождения (может быть несколько)
      const currentTestings = state.testingList.filter((testing) =>
        canContinueStatusesList.includes(testing.status.id),
      )

      // Текущее прохождение (последнее по ID)
      const currentTesting = maxBy(currentTestings, 'id')

      commit('setTesting', currentTesting)

      await dispatch('fetchAttemptList', state.testing.id)
      // Текущая попытка прохождения
      const testingCurrentAttempt = state.attemptList.find(
        (attempt) => attempt.isCurrent,
      )

      commit('setAttempt', testingCurrentAttempt)

      // Попытка прохождения начата
      const attemptStarted =
        state.attempt.status.id === Constants.testingAttemptStatus.STARTED
      // Попытка прохождения НЕ начата
      const attemptNotStarted =
        state.attempt.status.id === Constants.testingAttemptStatus.NOT_STARTED

      if (attemptStarted) {
        // Время на выполнение теста, в мс
        const testingTime = state.test.testingTime * millisecondsInMinute

        // Дата начала прохождения теста, в мс
        const startedAt = state.attempt.startedAt * millisecondsInSecond

        // Текущее серверное время, в сек
        const { data: currentTime } = await ApiTimestamp.get()

        // Оставшееся время у активной попытки со статусом "Начата"
        const estimatedTime =
          testingTime - (currentTime * millisecondsInSecond - startedAt)

        // Если время не вышло, то попытку можно продолжить
        if (estimatedTime > 0) {
          commit('setEstimatedTime', estimatedTime)
          commit('setTestingMeta', {
            key: 'testingCanContinue',
            value: true,
          })
          await dispatch('fetchAnswerListActive')
          commit('setAnswerListMeta')
        } else {
          // Если время вышло, принудительно отправляем на проверку
          await dispatch('sendTestForChecking')
        }
      } else if (attemptNotStarted) {
        // Если попытка активная, но статус "Не начата", стартуем попытку
        commit('setTestingMeta', {
          key: 'testingCanStart',
          value: true,
        })
      }

      return false
    }

    // Допустимые для начала прохождения статусы
    const canStartStatusesList = [
      Constants.testingStatus.RETURNED_QUESTIONS,
      Constants.testingStatus.ANNULLED,
      Constants.testingStatus.RETURNED_ANOTHER_VARIANT,
    ]

    // Нет прохождений ктп-теста
    const hasNoTestings = state.testingList.length === 0

    // Есть ли в списке прохождений, доступное для начала
    const containsCanStartStatus = state.testingList.some((testing) =>
      canStartStatusesList.includes(testing.status.id),
    )

    if (isAvailableByDateTime && (hasNoTestings || containsCanStartStatus)) {
      commit('setTestingMeta', {
        key: 'testingCanStart',
        value: true,
      })
    }

    if (containsCanStartStatus) {
      const lastTesting = state.testingList[state.testingList.length - 1]

      commit('setTesting', lastTesting)
    }

    return false
  },

  async sendMessageForTeacher({ state, commit }, payload) {
    const response = await ApiAccountStudentTestTestingReturnByStudent.put(
      state.testing.id,
      payload,
    )

    commit('setTesting', response.data)
  },

  /**
   * Отправка замечания по вопросу
   * @param {Object} context
   * @param {Object} payload
   * @param {number} payload.questionId
   * @param {string} payload.questionBody
   * @param {number} [payload.testId]
   * @param {number} [payload.ctpId]
   * @param {number} [payload.certId]
   */
  async sendReport(context, payload) {
    const data = {
      question: {
        id: payload.questionId,
      },
      test: {
        id: payload.testId,
      },
      questionBody: payload.questionBody,
    }

    if (payload.ctpId) {
      data.ctp = {
        id: payload.ctpId,
      }
    }

    if (payload.certId) {
      data.cert = {
        id: payload.certId,
      }
    }

    await ApiAskQuestion.post(payload.questionId, data)
      .then(() => {
        showToast(
          i18n.global.t('store.student.toasted_message.message_has_been_sent'),
        )
      })
      .catch((error) => {
        showToast(
          i18n.global.t('store.student.toasted_message.error_has_occurred'),
          'error',
        )

        throw error
      })
  },

  /**
   * Сохраняет все вопросы из меты в localStorage (весь массив заменяется)
   * @param {Object} context
   * @param {Object} context.state
   * @param {number} attemptId
   */
  updateSnapshot({ state }, attemptId) {
    const questions = state.answerListMeta.map((answer) => ({
      status: answer.status,
      content: answer.content,
      id: answer.id,
    }))

    // Дата начала прохождения теста, в сек
    const timeCreated = state.attempt.startedAt

    snapshotTesting.questionsSet({
      attemptId,
      questions,
      timeCreated,
    })
  },

  /**
   * Заполняет ответы вопросов из сохраненного снэпшота в локалсторадже
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {number} attemptId
   */
  async fillAnswersFromSnapshot({ state, commit }, attemptId) {
    const questions = await snapshotTesting.questionsGet(attemptId)

    if (questions) {
      questions.forEach((question) => {
        if (question.content) {
          const questionId = question.id
          const index = state.answerListMeta.findIndex(
            (answer) => answer.id === questionId,
          )

          if (index > -1) {
            commit('setAnswerMeta', {
              index,
              key: 'content',
              value: question.content,
            })
          }
        }
      })
    }
  },

  async logGoToTestingResults(context, { testId, testTypeId }) {
    await ApiUserAction.post({
      actionTypeId: trackActionTypes.CLICK_GO_TO_RESULTS_BTN,
      entityId: Number(testId),
      testTypeId,
    })
  },

  async logStartTestingExtramural(context, testId) {
    await ApiUserAction.post({
      actionTypeId: trackActionTypes.CLICK_START_TEST_BTN,
      entityId: Number(testId),
      testTypeId: testTypes.EXTRAMURAL,
    })
  },

  async logStartTestingFullTime(context, testId) {
    await ApiUserAction.post({
      actionTypeId: trackActionTypes.CLICK_START_TEST_BTN,
      entityId: Number(testId),
      testTypeId: testTypes.FULL_TIME,
    })
  },

  async logStartTestingFamily(context, testId) {
    await ApiUserAction.post({
      actionTypeId: trackActionTypes.CLICK_START_TEST_FE_CERT_BTN,
      entityId: Number(testId),
    })
  },
}
