import {
  getFullName,
  getNameAbbreviation,
  getNameWithShortLastName,
} from '@/helpers/fullName'

export default {
  fullName(state) {
    return getFullName(state.profile)
  },

  fullNameWithShortLastName(state) {
    return getNameWithShortLastName(state.profile)
  },

  nameAbbreviation(state) {
    return getNameAbbreviation(state.profile)
  },

  isSchoolPlatformDemoAllowed(state) {
    return (
      Boolean(state.schoolPlatform.isDemoAllowed) ||
      Boolean(state.schoolPlatform.isDemoAllowedFe)
    )
  },

  isAvailableAnyCommercialOffer(state) {
    return Boolean(!state.profile.hideOffer)
  },
}
