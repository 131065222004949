import { guestShowcaseRoutes } from '@/routes/showcase/guest/guestShowcaseRoutes'
import { StoreUserShowcase } from '@/StoreUserShowcase'

const checkRole = (to, from, next) => {
  if (StoreUserShowcase.store.getters['common/user/isClient']) {
    next({
      name: 'client-showcase',
    })
  } else if (StoreUserShowcase.store.getters['common/user/isStudent']) {
    next({
      name: 'student-showcase',
    })
  } else {
    next()
  }
}

export const guestRoutes = [
  {
    path: '/',
    component: () => import('@/layouts/showcase/GuestMain.vue'),

    children: [
      ...guestShowcaseRoutes,
    ],
  },
]

guestRoutes.forEach((route) => {
  route.beforeEnter = checkRole
})
