import { formatItem as formatCalendarItem } from '@/helpers/Calendar'
import { getTimestamp, offset } from '@/helpers/dateFormatHelper'
import lessonOnlineStatus from '@/helpers/lessonOnlineStatus'
import { cloneDeep, merge } from 'lodash'

/**
 * @private
 * @param {CalendarItemI} lesson
 * @returns {string}
 */
const assignStatusToLesson = (lesson) => {
  const { dateStart, timeEnd, timeStart } = lesson

  const lessonEnd = getTimestamp(new Date(`${dateStart}T${timeEnd}`)) + offset
  const lessonStart =
    getTimestamp(new Date(`${dateStart}T${timeStart}`)) + offset
  const currentDate = getTimestamp(new Date())

  if (currentDate - lessonEnd > 0) {
    return lessonOnlineStatus.PASSED
  }

  if (currentDate - lessonStart >= 0 && currentDate - lessonEnd <= 0) {
    return lessonOnlineStatus.IN_PROGRESS
  }

  // TODO: убедиться что онлайн-урок содержит признак isSigned
  return lesson?.extraData?.isSigned
    ? lessonOnlineStatus.SIGNED
    : lessonOnlineStatus.AVAILABLE
}

export default {
  /**
   * Онлайн-уроки с дополнительными данными
   * Уроки без темы не должны отображаться в ЛК ученика
   * @param {{
   *   lessons: CalendarItemI[]
   * }} state
   * @returns {{extraData: {Object}, id: number, type: {id: number, name: string}}[]}
   */
  lessonsWithExtraData(state) {
    return cloneDeep(state.lessons)
      .filter((lesson) => lesson.name !== '')
      .map((lesson) =>
        merge(formatCalendarItem(lesson), {
          extraData: {
            status: assignStatusToLesson(lesson),
          },
        }),
      )
  },
}
