import { mainSchool } from '@/helpers/const/AppData'
import { isLocalNetwork } from '@/helpers/const/isLocalNetwork'
import {
  fetchTelegramBotLink,
  unlinkTelegramBot,
} from '@/helpers/profile/telegramBot'
import ApiPasswordChange from 'ApiRest/Api/Account/Client/PasswordChange'
import ApiProfile from 'ApiRest/Api/Account/Client/Profile'
import { ApiAccountClientProfileLanguage } from 'ApiRest/Api/Account/Client/Profile/Language'
import ApiSchool from 'ApiRest/Api/Account/Client/School'
import { ApiProfilePhoto } from 'ApiRest/Api/Account/Profile/Photo'
import ApiNotification from 'ApiRest/Api/Notification'
import ApiNotificationEmail from 'ApiRest/Api/Notification/Email'
import ApiNotificationMobileDevice from 'ApiRest/Api/Notification/MobileDevice'
import ApiSubdomainList from 'ApiRest/Api/School/UnionSubDomain'
import UserSettings from 'ApiRest/Api/UserSettings'

export default {
  async fetchProfile({ commit, dispatch }) {
    const { data } = await ApiProfile.get()

    commit('setProfile', data)
    dispatch('setUserEmail')
    await dispatch('setLocale')
  },

  setUserEmail({ state, commit }) {
    commit(
      'common/user/setUserValue',
      {
        key: 'email',
        value: state.profile.email,
      },
      {
        root: true,
      },
    )
  },

  async setLocale({ commit, dispatch }) {
    await dispatch('common/locale/fetchLangOptions', null, {
      root: true,
    })

    dispatch('common/locale/setLangDefault', null, {
      root: true,
    })

    commit('common/locale/setUserType', 'client', {
      root: true,
    })

    commit('common/locale/setInstitutionType', 'school', {
      root: true,
    })

    await dispatch('common/locale/changeLocale', null, {
      root: true,
    })
  },

  updateProfile({ state }) {
    return ApiProfile.patch(state.profile)
  },

  updateProfilePassword({ state }) {
    return ApiPasswordChange.patch(state.passwords)
  },

  async updateLang({ commit }, payload) {
    const { data } = await ApiAccountClientProfileLanguage.put({
      language: payload,
    })

    commit('setProfile', data)
  },

  async fetchTelegramBotLink({ commit }) {
    const { isLinked, link } = await fetchTelegramBotLink()

    commit('setEventsTelegramIsLinked', isLinked)
    commit('setEventsTelegramLink', link)
  },

  async unlinkTelegramBot({ commit }) {
    const { link } = await unlinkTelegramBot()

    commit('setEventsTelegramIsLinked', false)
    commit('setEventsTelegramLink', link)
  },

  async fetchUserSettings({ commit }) {
    const { data } = await UserSettings.get({
      name: 'commonSettings',
    })

    if (data !== null) {
      commit('setUserSettings', data)
    }
  },

  async updateUserSettings({ state, commit }, payload) {
    const params = {
      name: 'commonSettings',
      data: {
        ...state.userSettings?.data,
        ...payload,
      },
    }

    await UserSettings.put(params).then(({ data }) => {
      commit('setUserSettings', data)
    })
  },

  async fetchSchoolPlatform({ commit }) {
    let { id } = mainSchool

    if (!isLocalNetwork()) {
      const domain = window.domains.main
      const { data: subdomainsList = [] } = await ApiSubdomainList.get(domain)

      commit('setSubdomains', subdomainsList)

      if (subdomainsList.length) {
        const school = subdomainsList.find((subdomain) => subdomain.self)

        if (school) {
          id = school.schoolId
        }
      }
    }

    const { data } = await ApiSchool.get(id)

    commit('setSchoolPlatform', data)
  },

  async uploadProfilePhoto({ commit }, payload) {
    const { data } = await ApiProfilePhoto.post(payload)

    commit('setProfileValue', {
      key: 'photo',
      value: data,
    })
  },

  async removeProfilePhoto({ commit }) {
    await ApiProfilePhoto.delete()

    commit('setProfileValue', {
      key: 'photo',
      value: null,
    })
  },

  async fetchNotifications({ commit }) {
    const { data } = await ApiNotification.get()

    commit('setNotifications', data)
  },

  subscribeEmailNotifications() {
    return ApiNotificationEmail.post()
  },

  unsubscribeEmailNotifications() {
    return ApiNotificationEmail.delete()
  },

  subscribeMobileDeviceNotifications() {
    return ApiNotificationMobileDevice.post()
  },

  unsubscribeMobileDeviceNotifications() {
    return ApiNotificationMobileDevice.delete()
  },
}
