import { teenologyUrl } from '@/consts/common/teenologyUrl'
import { getShowcaseDomain } from '@/helpers/getShowcaseDomain'
import { mainTabsClient } from '@/helpers/mainTabs'

/**
 * @returns {Object}
 */
export default () => ({
  currentTab: mainTabsClient[Object.keys(mainTabsClient)[0]].id,

  links: [
    {
      link: {
        name: 'client-contracts',
      },
      labelPath: 'nav.contracts',
      class: 'm-contracts',
      notifications: 0,
    },
    {
      link: {
        name: 'client-tariffs',
      },
      labelPath: 'nav.contracts',
      class: 'm-contracts',
      notifications: 0,
    },
    {
      link: {
        name: 'client-online-school',
      },
      labelPath: 'nav.online_school',
      class: 'm-online-school',
      notifications: 0,
    },
    {
      link: {
        name: 'client-payment',
      },
      labelPath: 'nav.payments',
      class: 'm-bills',
      notifications: 0,
    },
    {
      link: {
        name: 'client-certification',
      },
      labelPath: 'nav.attestation',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'client-lessons',
      },
      labelPath: 'nav.lessons',
      class: 'm-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'client-statements',
      },
      labelPath: 'nav.statement',
      class: 'm-transcript',
      notifications: 0,
    },
    {
      link: {
        name: 'client-income-diagnostics',
      },
      labelPath: 'nav.income_diagnostics',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'client-news',
      },
      labelPath: 'nav.news',
      class: 'm-news',
      notifications: 0,
    },
    {
      link: {
        name: 'client-library-enrollment',
      },
      labelPath: 'nav.library_enrollment',
      class: 'm-library-enrollment',
      notifications: 0,
      isExternal: true,
      externalUrl: 'https://form.xn--80aatfgkndeix6k.xn--p1ai/?id=2477',
    },
    {
      link: {
        name: 'client-schedule-lessons',
      },
      labelPath: 'nav.schedule',
      class: 'm-schedule-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'client-calendar',
      },
      labelPath: 'nav.calendar',
      class: 'm-calendar',
      notifications: 0,
    },
    {
      link: {
        name: 'client-goals',
      },
      labelPath: 'nav.goals',
      class: 'm-goals',
      notifications: 0,
    },
    {
      link: {
        name: 'client-messages',
      },
      labelPath: 'nav.messages',
      class: 'm-messages',
      notifications: 0,
    },
    {
      link: {
        name: 'client-notifications',
      },
      labelPath: 'nav.notifications',
      class: 'm-notifications',
      notifications: 0,
    },
    {
      link: {
        name: 'client-events',
      },
      labelPath: 'nav.events',
      class: 'm-events',
      notifications: 0,
      isExternal: true,
      externalUrl: 'https://events.iblschool.ru/',
    },
    {
      link: {
        name: 'client-showcase',
      },
      labelPath: 'nav.marketplace',
      class: 'm-showcase',
      notifications: 0,
      isExternal: true,
      externalUrl: `${getShowcaseDomain()}/client`,
    },
    {
      link: {
        name: 'client-courses',
      },
      labelPath: 'nav.courses',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'client-teenology',
      },
      labelPath: 'nav.teenology',
      class: 'm-teenology',
      notifications: 0,
      isExternal: true,
      externalUrl: teenologyUrl,
    },
    {
      link: {
        name: 'client-support',
      },
      labelPath: 'nav.support',
      class: 'm-help',
      notifications: 0,
    },
    {
      link: {
        name: 'client-library',
      },
      labelPath: 'nav.library',
      class: 'm-library',
      notifications: 0,
    },
  ],

  linksFamily: [
    {
      link: {
        name: 'client-contracts',
      },
      labelPath: 'nav.contracts',
      class: 'm-contracts',
      notifications: 0,
    },
    {
      link: {
        name: 'client-tariffs',
      },
      labelPath: 'nav.contracts',
      class: 'm-contracts',
      notifications: 0,
    },
    {
      link: {
        name: 'client-online-school',
      },
      labelPath: 'nav.online_school',
      class: 'm-online-school',
      notifications: 0,
    },
    {
      link: {
        name: 'client-payment',
      },
      labelPath: 'nav.payments',
      class: 'm-bills',
      notifications: 0,
    },
    {
      link: {
        name: 'client-certification',
      },
      // TODO: i18n
      labelPath: 'Ведомость аттестации',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'client-lessons',
      },
      labelPath: 'nav.lessons',
      class: 'm-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'client-income-diagnostics',
      },
      labelPath: 'nav.income_diagnostics',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'client-news',
      },
      labelPath: 'nav.news',
      class: 'm-news',
      notifications: 0,
    },
    {
      link: {
        name: 'client-library-enrollment',
      },
      labelPath: 'nav.library_enrollment',
      class: 'm-library-enrollment',
      notifications: 0,
      isExternal: true,
      externalUrl: 'https://form.xn--80aatfgkndeix6k.xn--p1ai/?id=2477',
    },
    {
      link: {
        name: 'client-schedule-lessons',
      },
      labelPath: 'nav.schedule',
      class: 'm-schedule-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'client-calendar',
      },
      labelPath: 'nav.calendar',
      class: 'm-calendar',
      notifications: 0,
    },
    {
      link: {
        name: 'client-goals',
      },
      labelPath: 'nav.goals',
      class: 'm-goals',
      notifications: 0,
    },
    {
      link: {
        name: 'client-messages',
      },
      labelPath: 'nav.messages',
      class: 'm-messages',
      notifications: 0,
    },
    {
      link: {
        name: 'client-notifications',
      },
      labelPath: 'nav.notifications',
      class: 'm-notifications',
      notifications: 0,
    },
    {
      link: {
        name: 'client-events',
      },
      labelPath: 'nav.events',
      class: 'm-events',
      notifications: 0,
      isExternal: true,
      externalUrl: 'https://events.iblschool.ru/',
    },
    {
      link: {
        name: 'client-showcase',
      },
      labelPath: 'nav.marketplace',
      class: 'm-showcase',
      notifications: 0,
      isExternal: true,
      externalUrl: `${getShowcaseDomain()}/client`,
    },
    {
      link: {
        name: 'client-statements',
      },
      // TODO: i18n
      labelPath: 'Ведомость курсов',
      class: 'm-transcript',
      notifications: 0,
    },
    {
      link: {
        name: 'client-statements-online-school',
      },
      // TODO: i18n
      labelPath: 'Ведомость IBLS+',
      class: 'm-transcript',
      notifications: 0,
    },
    {
      link: {
        name: 'client-courses',
      },
      labelPath: 'nav.courses',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'client-teenology',
      },
      labelPath: 'nav.teenology',
      class: 'm-teenology',
      notifications: 0,
      isExternal: true,
      externalUrl: teenologyUrl,
    },
    {
      link: {
        name: 'client-support',
      },
      labelPath: 'nav.support',
      class: 'm-help',
      notifications: 0,
    },
    {
      link: {
        name: 'client-library',
      },
      labelPath: 'nav.library',
      class: 'm-library',
      notifications: 0,
    },
  ],
})
