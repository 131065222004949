import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setUserSettings(state, payload) {
    state.userSettings = payload
  },

  setProfile(state, payload) {
    state.profile = payload
  },

  setProfileValue(state, { key, value }) {
    state.profile[key] = value
  },

  setSchool(state, payload) {
    state.school = payload
  },

  setNotifications(state, payload) {
    state.notifications = payload
  },

  setEventsTelegramIsLinked(state, payload) {
    state.events.telegramBot.isLinked = payload
  },

  setEventsTelegramLink(state, payload) {
    state.events.telegramBot.link = payload
  },
}
