import authHelper from '@/helpers/auth'
import { isMobileDomain } from '@/helpers/domain'
import { feature } from '@/helpers/feature'
import { filterNavLinks } from '@/helpers/filterNavLinks'
import { isEmpty } from 'lodash'

/** Ссылки навигации, доступные для ученика, если есть блокирующие аттестации */
const linksAvailableWhenHasBlockingCertifications = [
  'student-lessons-group',

  'certification',
]

/** Ссылки навигации для Заочного обучения */
const linksForExtramuralEducation = [
  'student-information',
  'student-lessons-group',

  'student-main',
  'student-income-diagnostics',
  'subjects',
  'lessons',
  'online-lessons',
  'student-online-school',
  'student-statements',
  'certification',
  'calendar',
  'goals',
  'notifications',
  'student-news',
  'student-library',
  'student-teenology',
  'student-support-questions',
  'student-support-ideas',
  'student-support-faq',
  'student-support-contacts',
  'student-courses',
  'student-messages',
]

/** Ссылки навигации для Семейного обучения */
const linksForFamilyEducation = [
  'student-information',
  'student-lessons-group',

  'student-main',
  'subjects',
  'student-online-school',
  'student-statements',
  'student-statements-online-school',
  'certification',
  'calendar',
  'notifications',
  'student-showcase',
  'student-courses',
  'student-messages',
  'student-news',
  'student-income-diagnostics',
  'student-teenology',
  'student-support-questions',
  'student-support-ideas',
  'student-support-faq',
  'student-support-contacts',
]

/** Ссылки навигации для Очного обучения */
const linksForFullTimeEducation = [
  'subjects',
  'online-lessons',
  'student-online-school',
  'student-statements',
  'certification',
  'calendar',
  'goals',
  'notifications',
  'student-news',
  'student-library',
  // TODO: temporary hide link. https://redmine.ibls.tech/issues/4139. Согласовано с Ушаковым
  // 'student-events',
  'schedule-tests',
  'schedule-lessons',
  'student-teenology',
  'student-support',
  'student-showcase',
  'student-courses',
  'student-messages',
  'student-income-diagnostics',
]

/** Ссылки навигации, доступные для ЦОК */
const linksAvailableWhenSchoolIsEducont = [
  'subjects',
  'lessons',
  'calendar',
  'goals',
  'notifications',
  'student-showcase',
]

const linksExcludedOnMobile = [
  // TODO: temporary hide link. https://redmine.ibls.tech/issues/4139. Согласовано с Ушаковым
  // 'student-events',
]

// TODO: убрать хардкодные id

/**
 * Список ID основных школ
 */
const mainSchoolIds = [
  1,
  4,
  3,
]

/**
 * Список ссылок навигации, доступных для основных школ
 */
const linksAvailableWhenSchoolIsMain = [
  // TODO: temporary hide link. https://redmine.ibls.tech/issues/4139. Согласовано с Ушаковым
  // 'student-events',
  'student-library',
  'student-teenology',
]

const linksCommercialOffers = [
  'student-showcase',
  'student-courses',
]

/**
 * Список ссылок навигации, доступных для школ по id
 */
const linksAvailableBySchoolId = {
  37: [
    'student-library',
  ],
  21: [
    'student-teenology',
  ],
}

export default {
  getLinks(state, getters, rootState, rootGetters) {
    const currentSchoolId = rootState.student.profile.profile.school.id
    const isEducontCurrentSchool =
      rootState.student.profile.profile?.school?.isEducont ?? false
    const isMainCurrentSchool = mainSchoolIds.includes(currentSchoolId)
    const hasBlockingCertifications =
      rootGetters['student/extramuralCertification/hasBlockingCertifications']
    const educationType = rootGetters['student/profile/educationType']
    const familyCertifications =
      rootState.student.familyCertification.certifications
    const extramuralCertifications =
      rootGetters['student/extramuralCertification/getAvailableCertifications']
    const fullTimeCertifications =
      rootGetters['student/fullTimeCertification/getAvailableCertifications']
    const isDemoUser = rootGetters['common/user/isDemoUser']
    const hasCertifications = [
      extramuralCertifications,
      familyCertifications,
      fullTimeCertifications,
    ].some((certifications) => certifications.length > 0)
    const hasModules = rootGetters['common/module/hasModules']
    const moduleSettingValue = rootGetters['common/module/moduleSettingValue']
    const hasIncomeDiagnostics =
      rootState.student.profile.profile?.hasIncomeDiagnostics ?? false
    const onlyDiagnostics =
      rootState.student.profile.profile?.onlyDiagnostics ?? false
    const isAvailableAnyCommercialOffer =
      rootGetters['student/profile/isAvailableAnyCommercialOffer']

    const linksAvailableForCurrentSchool =
      linksAvailableBySchoolId[currentSchoolId] ?? []

    const isEnrolled = rootGetters['student/profile/isEnrolled']

    const getNavLinks = () => {
      if (educationType.extramural) {
        return state.linksExtramural
      }

      if (educationType.family) {
        return state.linksFamily
      }

      return state.links
    }

    let navLinks = getNavLinks()

    // Если используется мобильная версия
    if (isMobileDomain) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => !linksExcludedOnMobile.includes(link.link.name),
      )
    }

    // Если отключены коммерческие предложения
    if (!isAvailableAnyCommercialOffer) {
      navLinks = navLinks.filter(
        (link) => !linksCommercialOffers.includes(link.link.name),
      )
    }

    // Если отключена входящая диагностика
    if (
      !feature.isIncomeDiagnostics ||
      (!hasIncomeDiagnostics && !onlyDiagnostics)
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'student-income-diagnostics',
      )
    }

    if (feature.isIncomeDiagnostics && hasIncomeDiagnostics) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'student-library',
      )
    }

    if (educationType.extramural) {
      navLinks = filterNavLinks(navLinks, (link) =>
        linksForExtramuralEducation.includes(link.link.name),
      )

      if (!isEnrolled) {
        navLinks = filterNavLinks(
          navLinks,
          (link) => link.link.name !== 'student-teenology',
        )

        navLinks = filterNavLinks(
          navLinks,
          (link) => link.link.name !== 'student-library',
        )
      }
    }

    if (educationType.family) {
      navLinks = filterNavLinks(navLinks, (link) => {
        if (
          link.link.name === 'student-statements' &&
          isEmpty(rootGetters['student/subjects/getSubjectsExtracurricular'])
        ) {
          return false
        }

        if (
          link.link.name === 'student-statements-online-school' &&
          isEmpty(rootGetters['student/subjects/getSubjectsOnlineSchool'])
        ) {
          return false
        }

        return linksForFamilyEducation.includes(link.link.name)
      })

      // Если нет аттестаций
      if (!hasCertifications) {
        navLinks = filterNavLinks(
          navLinks,
          (link) => link.link.name !== 'student-teenology',
        )
      }
    }

    if (educationType.fullTime) {
      navLinks = filterNavLinks(navLinks, (link) =>
        linksForFullTimeEducation.includes(link.link.name),
      )
    }

    // Если школа Educont
    if (isEducontCurrentSchool) {
      navLinks = filterNavLinks(navLinks, (link) =>
        linksAvailableWhenSchoolIsEducont.includes(link.link.name),
      )
    }

    // Если есть блокирующие аттестации
    if (hasBlockingCertifications) {
      navLinks = filterNavLinks(navLinks, (link) =>
        linksAvailableWhenHasBlockingCertifications.includes(link.link.name),
      )
    }

    // Если школы нет в списке mainSchoolIds
    if (!isMainCurrentSchool) {
      navLinks = filterNavLinks(
        navLinks,
        (link) =>
          linksAvailableForCurrentSchool.includes(link.link.name) ||
          !linksAvailableWhenSchoolIsMain.includes(link.link.name),
      )
    }

    // Если не подключен модуль "Онлайн-уроки"
    if (
      !hasModules([
        'online-lesson',
      ])
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'online-lessons',
      )
    }

    // Скрыть запрещенные разделы для ученика с демо-доступом
    if (isDemoUser) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => !authHelper.auth.isDemoUserAccessForbidden(link.link.name),
      )
    }

    // Если нет аттестаций
    if (!hasCertifications) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'certification',
      )
    }

    // Если не подключен модуль "Сообщения"
    if (
      !hasModules([
        'chat',
      ])
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'student-messages',
      )
    }

    // Если не подключен модуль "Поддержка"
    if (
      !hasModules([
        'support',
      ])
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'student-support',
      )
    } else {
      const linksMap = {
        questions: 'student-support-questions',
        ideas: 'student-support-ideas',
        faq: 'student-support-faq',
        contacts: 'student-support-contacts',
      }

      const removeNavLink = (payload) => {
        navLinks = filterNavLinks(
          navLinks,
          (link) => link.link.name !== payload,
        )
      }
      let currentEducationType = null

      Object.entries(educationType).forEach(
        ([
          key,
          value,
        ]) => {
          if (value) {
            currentEducationType = key
          }
        },
      )

      const settings = moduleSettingValue(
        'support',
        currentEducationType.toLowerCase(),
      )

      Object.entries(linksMap).forEach(
        ([
          settingName,
          linkName,
        ]) => {
          if (!settings?.some((setting) => setting.id === settingName)) {
            removeNavLink(linkName)
          }
        },
      )
    }

    // Если не подключен модуль "Витрина"
    if (
      !hasModules([
        'educational-showcase',
      ])
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'student-showcase',
      )
    }

    // Если не подключен модуль "Библиотека"
    if (
      !hasModules([
        'school-library',
      ])
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'student-library',
      )
    }

    // Если не подключен модуль "Курсы"
    if (
      !hasModules([
        'educational-courses',
      ])
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'student-courses',
      )
    }

    if (
      !hasModules([
        'online-school',
      ])
    ) {
      navLinks = filterNavLinks(
        navLinks,
        (link) => link.link.name !== 'student-online-school',
      )
    }

    return navLinks
  },
}
