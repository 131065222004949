import { RouterUser } from '@/RouterUser'
import DownloadHelper from '@/helpers/DownloadHelper'
import { mainSchool } from '@/helpers/const/AppData'
import { showToast, showToastPersistent } from '@/helpers/toast'
import { i18n } from '@/i18n/setup'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiCommonContract from 'ApiRest/Api/Account/Client/CommonContract'
import { ApiContractDocument } from 'ApiRest/Api/Account/Client/ContractDocument'
import ApiContractSettings from 'ApiRest/Api/Account/Client/ContractSettings'
import ApiFamilyEducationOfferForSchool from 'ApiRest/Api/Account/Client/FamilyEducationOfferForSchool'
import { ApiCustomerReceipt } from 'ApiRest/Api/Account/Client/Invoice/CustomerReceipt'
import ApiRequest from 'ApiRest/Api/Account/Client/Request'
import { ApiActionLog } from 'ApiRest/Api/Account/Client/Request/ActionLog'
import ApiAccountClientRequestConcludeRejected from 'ApiRest/Api/Account/Client/Request/ConcludeRejected'
import ApiRequestStudent from 'ApiRest/Api/Account/Client/Request/Student'
import ApiRequestDraft from 'ApiRest/Api/Account/Client/RequestDraft'
import ApiSchool from 'ApiRest/Api/Account/Client/School'
import ApiSimpleRequest from 'ApiRest/Api/Account/Client/SimpleRequest/SimpleRequest'
import { ApiDemoFeSimpleRequestCall } from 'ApiRest/Api/Auth/DemoFeSimple/RequestCall'
import { ApiFeServiceCheckPromocode } from 'ApiRest/Api/Auth/Fe/ServiceCheckPromocode'
import { ApiFeServiceDetails } from 'ApiRest/Api/Auth/Fe/ServiceDetails'
import { ApiCheckDocuments } from 'ApiRest/Api/Enrollment/Contract/CheckDocuments'
import { ApiStatusWebhook } from 'ApiRest/Api/Enrollment/Request/StatusWebhook'
import ApiOption from 'ApiRest/Api/Option'
import { ApiSendFeRequestEvent } from 'ApiRest/Api/TriggerSending/SendFeRequestEvent'
import { ApiTriggerNotification } from 'ApiRest/Api/TriggerSending/TriggerNotification'
import Constants from 'Constants'
import mammoth from 'mammoth/mammoth.browser'

const createStepContractData = () => ({
  heading: i18n.global.t('pages.request.steps.contract_data'),
  component: 'ContractData',
  isActive: false,
  isComplete: false,
})

const createStepClientData = () => ({
  heading: i18n.global.t('pages.request.steps.client_data'),
  component: 'ClientData',
  isActive: false,
  isComplete: false,
})

const createStepStudentData = () => ({
  heading: i18n.global.t('pages.request.steps.student_data'),
  component: 'StudentData',
  isActive: false,
  isComplete: false,
})

const createStepAllData = () => ({
  heading: i18n.global.t('pages.request.steps.all_data'),
  component: 'AllData',
  isActive: false,
  isComplete: false,
})

const createStepContractCreation = () => ({
  heading: i18n.global.t('pages.request.steps.contract_creation'),
  component: 'ContractCreation',
  isActive: false,
  isComplete: false,
})

const createStepByName = (name) => {
  switch (name) {
    case 'ContractData':
      return createStepContractData()

    case 'ClientData':
      return createStepClientData()

    case 'StudentData':
      return createStepStudentData()

    case 'AllData':
      return createStepAllData()

    case 'ContractCreation':
      return createStepContractCreation()

    default:
      return {}
  }
}

export default {
  initSteps({ commit }) {
    const steps = [
      createStepContractData(),
    ]

    steps[0].isActive = true

    commit('setSteps', steps)
  },

  updateSteps({ getters, commit }) {
    const stepNamesExtramural = [
      'ContractData',
      'ClientData',
      'StudentData',
      'AllData',
    ]

    const stepNamesFamily = [
      'ContractData',
      'ContractCreation',
    ]

    let stepNames = []

    if (getters.educationType.id === Constants.educationType.EXTRAMURAL) {
      stepNames = stepNamesExtramural
    }

    if (getters.educationType.id === Constants.educationType.FAMILY) {
      stepNames = stepNamesFamily
    }

    const steps = stepNames.map(
      (name) =>
        getters.steps.find((step) => step.component === name) ??
        createStepByName(name),
    )

    commit('setSteps', steps)
  },

  async fetchDraft({ commit }, id) {
    try {
      const { data, status } = await ApiRequestDraft.get(id)

      if (status === HttpStatusCodes.Ok) {
        commit('setDraftData', data)
      }
    } catch (error) {
      // TODO: -> component
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        showToast(
          i18n.global.t('store.client.toasted_message.request_was_not_found'),
          'error',
        )

        RouterUser.router.push({
          name: 'client-main',
        })
      }

      throw error
    }
  },

  async createDraft({ state, commit, dispatch }) {
    try {
      const { data, status } = await ApiRequestDraft.post(state.contract)

      if (status === HttpStatusCodes.Created) {
        commit('setDraftData', data)

        // TODO: -> component
        showToast(
          i18n.global.t('store.client.toasted_message.data_successfully_save'),
        )

        await dispatch('client/contracts/fetchDrafts', null, {
          root: true,
        })
      }
    } catch (error) {
      // TODO: -> component
      showToast(
        i18n.global.t('store.client.toasted_message.error_saving_draft'),
        'error',
      )

      throw error
    }
  },

  async updateDraft({ state }) {
    try {
      const { status } = await ApiRequestDraft.patch(
        state.contract.id,
        state.contract,
      )

      // TODO: -> component
      if (status === HttpStatusCodes.Ok) {
        showToast(
          i18n.global.t('store.client.toasted_message.data_successfully_save'),
        )
      }
    } catch (error) {
      // TODO: -> component
      showToast(
        i18n.global.t('store.client.toasted_message.error_saving_draft'),
        'error',
      )

      throw error
    }
  },

  async saveDraft({ state, dispatch }) {
    if (state.contract?.id) {
      await dispatch('updateDraft')
    } else {
      await dispatch('createDraft')
    }
  },

  async fetchRequest({ commit }, id) {
    try {
      const { data, status } = await ApiRequest.get(id)

      if (status === HttpStatusCodes.Ok) {
        commit('setRequestData', data)
      }
    } catch (error) {
      // TODO: -> component
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        showToast(
          i18n.global.t('store.client.toasted_message.contract_was_not_found'),
          'error',
        )

        RouterUser.router.push({
          name: 'client-main',
        })
      }

      throw error
    }
  },

  async createRequest({ state, commit, dispatch }) {
    try {
      // TODO: Заменить на ConvertEmptyDataToDefaultValue
      commit('deleteStudentAdditionalData')
      commit('deleteStudentSnils')
      commit('deleteClientSnils')

      const { data, status } = await ApiRequest.post(state.contract)

      commit('setRequestNumber', data?.id)

      await dispatch('sendStatusWebhook')

      // TODO: -> component
      if (status === HttpStatusCodes.Created) {
        showToast(
          i18n.global.t(
            'store.client.toasted_message.application_was_successfully_sent_to_the_manager',
          ),
        )

        if (state.contract?.id) {
          await ApiRequestDraft.delete(state.contract.id)
        }

        if (
          state.contract.educationType.id === Constants.educationType.FAMILY
        ) {
          RouterUser.router.push({
            name: 'client-payment-the-invoice',
            params: {
              invoiceId: data.invoice.id,
            },
          })
        } else {
          RouterUser.router.push({
            name: 'client-contracts',
          })
        }
      }
    } catch (error) {
      // TODO: -> component
      showToastPersistent(error?.response?.data, 'error')

      throw error
    }
  },

  async updateRequest({ state }) {
    try {
      await ApiRequest.put(state.contract.id, state.contract)

      showToast(
        i18n.global.t('store.client.toasted_message.data_successfully_updated'),
      )

      RouterUser.router.push({
        name: 'client-contracts',
      })
    } catch (error) {
      // TODO: -> component
      showToastPersistent(error?.response?.data, 'error')

      throw error
    }
  },

  async saveSimpleRequest({ state }) {
    await ApiSimpleRequest.patch(state.contract, state.contract.id)
  },

  async updateSimpleRequest({ state }) {
    await ApiRequest.patch(state.contract.id, state.contract)

    RouterUser.router.push({
      name: 'client-contracts',
    })
  },

  async fetchContract({ commit }, contractId) {
    try {
      const { data, status } = await ApiCommonContract.get(contractId)

      if (status === HttpStatusCodes.Ok) {
        commit('setContractData', data)
      }
    } catch (error) {
      if (error.response.status === HttpStatusCodes.NotFound) {
        showToast(
          i18n.global.t('store.client.toasted_message.contract_was_not_found'),
          'error',
        )

        RouterUser.router.push({
          name: 'client-main',
        })
      }

      throw error
    }
  },

  async fetchExistingStudent({ commit }, studentId) {
    const { data } = await ApiRequestStudent.get(studentId)

    commit('setContractStudent', data)
  },

  fetchEducationTypes({ commit }) {
    return ApiOption.getEducationTypes().then(({ data }) => {
      commit('setEducationTypes', data)
    })
  },

  fetchFamilyEducationGrades({ commit }) {
    // Семейное обучение доступно только для школы "Наши пенаты"
    const params = {
      school: {
        id: mainSchool.id,
      },
      educationType: {
        id: Constants.educationType.FAMILY,
      },
    }

    return ApiOption.getGrades(params).then(({ data }) => {
      commit('setFamilyEducationGrades', data)
    })
  },

  fetchEnrollmentContractSubmissionTypes({ state, commit }) {
    return ApiOption.getEnrollmentContractSubmissionTypes().then(({ data }) => {
      commit('setEnrollmentContractSubmissionTypes', data)
      // TODO: когда добавится оферта - убрать
      commit('setSubmissionType', state.submissionTypes[0])
    })
  },

  fetchSchools({ state, commit, rootState }) {
    return ApiOption.getSchools({
      expand: 'code,city,educationTypes,grades,currentAcademicYearPreset',
      educationType: {
        id: Constants.educationType.EXTRAMURAL,
      },
    }).then(({ data: schools }) => {
      const subdomains = rootState.client.profile.subDomains
      const hasSubDomains = Boolean(subdomains?.length)

      commit('setIsSchools3rdParty', hasSubDomains)

      let result = []

      if (state.isSchools3rdParty) {
        const subDomainsIds = subdomains.map((subDomain) => subDomain.schoolId)

        result = schools.filter((school) => subDomainsIds.includes(school.id))
      } else {
        result = schools.filter((school) => !school.is3rdParty)
      }

      commit('setSchools', result)
    })
  },

  fetchKinshipDegrees({ commit }) {
    return ApiOption.getKinshipDegrees().then(({ data }) => {
      commit('setKinshipDegrees', data)
    })
  },

  fetchCitizenships({ commit }) {
    return ApiOption.getCitizenships().then(({ data }) => {
      commit('setCitizenships', data)
    })
  },

  fetchMoscowRegistrationTypes({ commit }) {
    return ApiOption.getMoscowRegistrationTypes().then(({ data }) => {
      commit('setMoscowRegistrationTypes', data)
    })
  },

  fetchMoscowRegistrationConfirmDocTypes({ commit }) {
    return ApiOption.getMoscowRegistrationConfirmDocTypes().then(({ data }) => {
      commit('setMoscowRegistrationConfirmDocTypes', data)
    })
  },

  fetchCountries({ commit }) {
    return ApiOption.getCountries().then(({ data }) => {
      commit('setCountries', data)
    })
  },

  fetchSexes({ commit }) {
    return ApiOption.getSexes().then(({ data }) => {
      commit('setSexes', data)
    })
  },

  fetchFamilyCategories({ commit }) {
    return ApiOption.getFamilyCategories().then(({ data }) => {
      commit('setFamilyCategories', data)
    })
  },

  fetchFamilyEducationOfferForSchool({ commit }, schoolId) {
    return ApiFamilyEducationOfferForSchool.get(schoolId).then(({ data }) => {
      commit('setFamilyEducationOfferForSchool', data)
    })
  },

  async fetchAcademicYearPresetOption({ commit }) {
    const { data } = await ApiOption.getAcademicYearPreset()

    commit('setAcademicYearPresetOption', data)
  },

  async fetchContractSettings({ commit }, schoolId) {
    const { data } = await ApiContractSettings.get(schoolId)

    commit('setContractSettings', data)
  },

  async downloadContractDocument({ state }, documentType) {
    const response = await ApiContractDocument.get(
      state.contract.id,
      documentType,
    )

    DownloadHelper.downloadBlob(response)
  },

  async fetchCheckTemplatesExistence({ state, commit }) {
    const { data } = await ApiCheckDocuments.get(state.contract.id)

    commit('setExistingTemplates', data)
  },

  async fetchConcludeRejected({ state }) {
    await ApiAccountClientRequestConcludeRejected.put(state.contract.id)

    RouterUser.router.push({
      name: 'client-contracts',
    })
  },

  async fetchPromocodeDetails({ commit }, promocode) {
    const { data } = await ApiFeServiceCheckPromocode.post({
      code: promocode,
    })

    commit('setPromocodeDetails', data)
  },

  async fetchOfferText({ state, commit }) {
    const { data } = await ApiFamilyEducationOfferForSchool.get(
      state.contract.school.id,
    )

    const result = await mammoth.convertToHtml({
      arrayBuffer: data,
    })

    commit('setOfferText', result.value)
  },

  async fetchServiceDetails({ commit }) {
    const { data } = await ApiFeServiceDetails.get()

    commit('setServiceDetails', data)
  },

  async fetchInvoice({ state, commit, dispatch }) {
    const { data } = await ApiRequest.post({
      ...state.contract,
      paymentType: state.contract.paymentType.id,
    })

    commit('setInvoice', data)

    await dispatch('sendStatusWebhook')
    await dispatch('actionLog')
  },

  async fetchCustomerReceipt({ state, commit }) {
    const { data } = await ApiCustomerReceipt.get(state.invoice.id)

    commit('setCustomerReceipt', data)
  },

  async requestCall({ getters }) {
    await ApiDemoFeSimpleRequestCall.put({
      source: 'enrollment-request',
      grade: getters.contractGrade?.id,
    })
  },

  async triggerNotification(context, notificationId) {
    await ApiTriggerNotification.post(notificationId)
  },

  async sendFERequestEvent() {
    await ApiSendFeRequestEvent.post()
  },

  fillClientData({ rootState, commit }) {
    const { profile } = rootState.client.profile

    commit('setClientLastName', profile.lastName)
    commit('setClientFirstName', profile.firstName)
    commit('setClientEmail', profile.email)
    commit('setClientPhone', profile.phone)
  },

  fillStudentData({ state, commit }) {
    const { existingStudent } = state.contract

    commit('setStudentId', existingStudent.id)
    commit('setStudentLastName', existingStudent.lastName)
    commit('setStudentFirstName', existingStudent.firstName)
    commit('setStudentEmail', existingStudent.email)
  },

  fillOutStudentData({ commit }) {
    commit('unsetStudentId')
    commit('setStudentLastName', '')
    commit('setStudentFirstName', '')
    commit('setStudentEmail', '')
  },

  async sendStatusWebhook({ getters }) {
    const canSendWebhook =
      getters.statusData.requestSchoolId === 1 ||
      getters.statusData.requestSchoolId === 4

    if (canSendWebhook) {
      try {
        await ApiStatusWebhook.post(getters.statusData)
      } catch {
        /* empty */
      }
    }
  },

  async actionLog({ state }) {
    try {
      await ApiActionLog.post({
        actionTypeId: 13,
        entityId: state.invoice?.id,
      })
    } catch {
      /* empty */
    }
  },

  async fetchSchool({ commit }, schoolId) {
    const { data } = await ApiSchool.get(schoolId)

    commit('setSchoolData', data)
  },
}
