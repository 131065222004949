import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setProfile(state, payload) {
    state.profile = payload
  },

  setProfileValue(state, { key, value }) {
    state.profile[key] = value
  },

  setProfilePassword(state, payload) {
    state.passwords = payload
  },

  setNotifications(state, payload) {
    state.notifications = payload
  },

  setEventsTelegramIsLinked(state, payload) {
    state.events.telegramBot.isLinked = payload
  },

  setEventsTelegramLink(state, payload) {
    state.events.telegramBot.link = payload
  },

  setUserSettings(state, payload) {
    state.userSettings = payload
  },

  setSchoolPlatform(state, payload) {
    state.schoolPlatform = payload
  },

  setSubdomains(state, payload) {
    state.subDomains = payload
  },
}
