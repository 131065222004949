<template>
  <button class="btn" :class="$style.btn" type="button">
    <span
      v-if="isLoading"
      class="spinner-border spinner-border-sm mr-2"
      role="status"
      aria-hidden="true"
    ></span>

    <span v-if="label">{{ label }}</span>

    <slot></slot>
  </button>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ElButton',

  props: {
    label: {
      type: String,
      default: null,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style module lang="scss">
.btn:disabled {
  pointer-events: none;
}
</style>
