import { studentSubjectRoutes } from '@/routes/showcase/student/studentSubjectRoutes'

export const studentEducationRoutes = [
  {
    path: 'education',
    name: 'student-showcase-education',

    component: () => import('@/layouts/showcase/StudentEducationLayout.vue'),

    children: [
      ...studentSubjectRoutes,

      {
        path: 'purchases',
        name: 'student-showcase-purchases',
        component: () => import('@/pages/showcase/common/Purchases.vue'),
      },
    ],
  },
]
