import {
  types as calendarItemTypes,
  formatItem as formatCalendarItem,
} from '@/helpers/Calendar'
import { getTimestamp, offset } from '@/helpers/dateFormatHelper'
import lessonOnlineStatus from '@/helpers/lessonOnlineStatus'
import { cloneDeep, merge } from 'lodash'

/**
 * @private
 * @param {CalendarItemI} lesson
 * @returns {string}
 */
const assignStatusToLesson = (lesson) => {
  const { dateStart, timeEnd, timeStart } = lesson

  const lessonEnd = getTimestamp(new Date(`${dateStart}T${timeEnd}`)) + offset
  const lessonStart =
    getTimestamp(new Date(`${dateStart}T${timeStart}`)) + offset
  const currentDate = getTimestamp(new Date())

  if (currentDate - lessonEnd > 0) {
    return lessonOnlineStatus.PASSED
  }

  if (currentDate - lessonStart >= 0 && currentDate - lessonEnd <= 0) {
    return lessonOnlineStatus.IN_PROGRESS
  }

  // TODO: убедиться что онлайн-урок содержит признак isSigned
  return lesson?.extraData?.isSigned
    ? lessonOnlineStatus.SIGNED
    : lessonOnlineStatus.AVAILABLE
}

export default {
  /**
   * Онлайн-уроки без темы не должны отображаться в ЛК ученика
   * @param {{
   *   items: CalendarItemI[]
   * }} state
   * @returns {CalendarItemI[]}
   */
  events(state) {
    return cloneDeep(state.items)
      .filter(
        (item) =>
          !(
            Number(item?.type?.id) === calendarItemTypes.ONLINE_LESSON &&
            item?.name === ''
          ),
      )
      .map((item) => formatCalendarItem(item))
  },

  /**
   * Онлайн-уроки с дополнительными данными
   * Уроки без темы не должны отображаться в ЛК ученика
   * @param {{
   *   lessons: CalendarItemI[]
   * }} state
   * @returns {{extraData: {Object}, id: number, type: {id: number, name: string}}[]}
   */
  calendarEvents(state) {
    return cloneDeep(state.calendarItems)
      .filter(
        (item) =>
          !(
            Number(item?.type?.id) === calendarItemTypes.ONLINE_LESSON &&
            item?.name === ''
          ),
      )
      .map((item) => {
        if (Number(item?.type?.id) === calendarItemTypes.ONLINE_LESSON) {
          return merge(formatCalendarItem(item), {
            extraData: {
              status: assignStatusToLesson(item),
            },
          })
        }

        return formatCalendarItem(item)
      })
  },
}
